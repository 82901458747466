"use client";
import "react-perfect-scrollbar/dist/css/styles.css";
import Layout from "antd/es/layout/layout";
import ReactPerfectScroll from "react-perfect-scrollbar";
import { useEffect, useState } from "react";
import { fetchDashboardData, checkUserAuthentication, checkWebsiteBlueprintStatus } from "../functions";
import ServiceAutomationBox from "./components/service-automation-box";
import SetupModal from "./components/setup-modal";
import AddChannelModal from './components/add-channel-modal';
import { message, Spin } from 'antd';

type DashboardData = {
  marketingChannels: string[];
  activeChannels: string[];
  selectedPlans: string[];
  customPricingEnabled: boolean;
  completedFields?: any;
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [setupModalVisible, setSetupModalVisible] = useState(false);
  const [addChannelModalVisible, setAddChannelModalVisible] = useState(false);
  const [currentService, setCurrentService] = useState<string | null>(null);
  const [currentServiceTitle, setCurrentServiceTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasPendingWebsite, setHasPendingWebsite] = useState(false);
  const checkAndRedirectIfNeeded = () => {
    if (!localStorage.getItem("accessToken")) {
      window.location.href = "/sign-in";
    } else { 
      checkUserAuthentication(); 
    }
  };

  useEffect(() => {
    checkAndRedirectIfNeeded();
    fetchDashboardData().then((data) => {
      setDashboardData(data);
      setIsLoading(false);
      console.log("Dashboard data loaded:", data);
    }).catch(error => {
      console.error("Error fetching dashboard data:", error);
      message.error("Failed to load dashboard data");
      setIsLoading(false);
    });
  }, []);


  useEffect(() => {
    const checkPendingWebsite = async () => {
      console.log("Checking for pending website");
      try {
        const isPending = await checkWebsiteBlueprintStatus();
        console.log("Is Pending", isPending);
        setHasPendingWebsite(isPending ?? false);
      } catch (error) {
        console.error('Error checking website status:', error);
      }
    };

    checkPendingWebsite();
  }, []);

  const handleStartSetup = (serviceId: string, serviceTitle: string, needsSetup: boolean) => {
    setCurrentService(serviceId);
    setCurrentServiceTitle(serviceTitle);
    if (needsSetup) {
      setSetupModalVisible(true);
    } else {
      if (dashboardData?.customPricingEnabled) {
        message.info("Custom pricing is enabled. Please contact customer support to activate this channel.");
        return;
      }
      setAddChannelModalVisible(true);
    }
  };

  const services = [
    {
      title: "Google Ads",
      description: "Manage and optimize your Google Ads campaigns",
      id: "google_ads"
    },
    {
      title: "Facebook & Instagram Ads",
      description: "Create and monitor Facebook & Instagram advertising campaigns",
      id: "meta_ads"
    },
    {
      title: "Website",
      description: "Let's get your website set up and optimized for search engines",
      id: "seo"
    },
    {
      title: 'Payment Processing & Point of Sale',
      description: 'Set up your payment processing and point of sale system',
      id: 'payment_processing'
    },
    {
      title: "Business Listing Management",
      description: "Manage your business listings across multiple platforms",
      id: "business_listings"
    },
    {
      title: "Text Marketing",
      description: "Engage customers through SMS campaigns",
      id: "text_marketing"
    },
    {
      title: "Email Marketing",
      description: "Create and automate email marketing campaigns",
      id: "email_marketing"
    },
    {
      title: "Social Media Management",
      description: "Manage all your social media accounts",
      id: "social_media"
    }
  ];

  const activeServices = services.filter(service => 
    dashboardData?.activeChannels?.includes(service.id)
  );

  const needsSetupServices = services.filter(service => 
    dashboardData?.marketingChannels?.includes(service.id) && 
    !dashboardData?.activeChannels?.includes(service.id)
  );

  const inactiveServices = services.filter(service => 
    !dashboardData?.marketingChannels?.includes(service.id) &&
    !dashboardData?.activeChannels?.includes(service.id)
  );

  return (
    <Layout className="!bg-white min-h-screen">
      <ReactPerfectScroll className="!max-h-[calc(100vh-180px)]">
        <div className="max-w-[1600px] mx-auto px-8 md:px-12 lg:px-16 py-12">
          {isLoading ? (
            <div className="flex justify-center items-center h-[60vh]">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {/* Active Channels Section */}
              {activeServices.length > 0 && (
                <div className="mb-16">
                  <div className="max-w-2xl mb-8">
                    <h2 className="text-4xl font-bold mb-5">Active Channels</h2>
                    <p className="text-gray-600 text-lg">
                      These are your currently active marketing channels. Click on any channel to manage its settings.
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
                    {activeServices.map((service) => (
                      <ServiceAutomationBox
                        key={service.id}
                        title={service.title}
                        description={service.description}
                        isEnabled={true}
                        isActive={true}
                        onStartSetup={() => handleStartSetup(service.id, service.title, false)}
                        serviceType={service.id}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Needs Setup Section */}
              {needsSetupServices.length > 0 && (
                <div className="mb-16">
                  <div className="max-w-2xl mb-8">
                    <h2 className="text-4xl font-bold mb-5">Channels Needing Setup</h2>
                    <p className="text-gray-600 text-lg">
                      Complete the setup for these channels to start using them.
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
                    {needsSetupServices.map((service) => (
                      <ServiceAutomationBox
                        key={service.id}
                        title={service.title}
                        description={service.description}
                        isEnabled={true}
                        isActive={false}
                        needsSetup={true}
                        onStartSetup={() => handleStartSetup(service.id, service.title, true)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Available Channels Section */}
              {inactiveServices.length > 0 && (
                <div>
                  <div className="max-w-2xl mb-8">
                    <h2 className="text-4xl font-bold mb-5">Available Channels</h2>
                    <p className="text-gray-600 text-lg">
                      Expand your marketing reach by adding these channels to your plan.
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12">
                    {inactiveServices.map((service) => (
                      <ServiceAutomationBox
                        key={service.id}
                        title={service.title}
                        description={service.description}
                        isEnabled={false}
                        isActive={false}
                        onStartSetup={() => handleStartSetup(service.id, service.title, false)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </ReactPerfectScroll>
      
      {setupModalVisible && (
        <SetupModal
          visible={setupModalVisible}
          onClose={() => setSetupModalVisible(false)}
          serviceName={currentService}
          setupData={dashboardData?.completedFields}
          hasPendingWebsite={hasPendingWebsite}
        />
      )}
      
      {addChannelModalVisible && (
        <AddChannelModal
          visible={addChannelModalVisible}
          onClose={() => setAddChannelModalVisible(false)}
          serviceName={currentService}
          serviceTitle={currentServiceTitle}
          currentChannels={dashboardData?.marketingChannels || []}
        />
      )}
    </Layout>
  );
};

export default Dashboard;
