import { Button, Card, Dropdown, MenuProps } from 'antd';
import { useState } from 'react';
import { RightOutlined, CheckCircleFilled, ClockCircleOutlined, DownOutlined } from '@ant-design/icons';
import { useRouter } from 'next/navigation';

interface ServiceAutomationBoxProps {
  title: string;
  description: string;
  isEnabled: boolean;
  onStartSetup: () => void;
  isActive?: boolean;
  needsSetup?: boolean;
  serviceType?: string;
}

const ServiceAutomationBox = ({ 
  title, 
  description, 
  isEnabled, 
  isActive = false,
  needsSetup = false,
  onStartSetup,
  serviceType,
}: ServiceAutomationBoxProps) => {
  const router = useRouter();

  const getMenuItems = (): MenuProps['items'] => {
    switch (serviceType) {
      case 'payment_processing':
        return [{
          key: 'viewPayments',
          label: <span className="text-base">View Payments Dashboard</span>,
          onClick: () => router.push('/payments')
        }];
      case 'seo':
        return [{
          key: 'viewWebsite',
          label: <span className="text-base">View Website</span>,
          onClick: () => router.push('/website-builder')
        }];
      case 'google_ads':
      case 'meta_ads':
        return [
          {
            key: 'viewLeads',
            label: <span className="text-base">View Leads</span>,
            onClick: () => router.push('/marketing/leads')
          },
          {
            key: 'viewContent',
            label: <span className="text-base">View Content</span>,
            onClick: () => router.push('/content')
          }
        ];
      case 'text_marketing':
        return [
          {
            key: 'viewCampaigns',
            label: <span className="text-base">View Campaigns</span>,
            onClick: () => router.push('/marketing/campaigns')
          },
          {
            key: 'viewMessages',
            label: <span className="text-base">View Messages</span>,
            onClick: () => router.push('/marketing/inbox')
          }
        ];
      case 'email_marketing':
        return [{
          key: 'viewCampaigns',
          label: <span className="text-base">View Campaigns</span>,
          onClick: () => router.push('/marketing/campaigns')
        }];
      case 'social_media':
        return [{
          key: 'viewContent',
          label: <span className="text-base">View Content</span>,
          onClick: () => router.push('/content')
        }];
      case 'business_listing':
        return [{
          key: 'viewLeads',
          label: <span className="text-base">View Leads</span>,
          onClick: () => router.push('/marketing/leads')
        }];
      default:
        return [];
    }
  };

  const menuItems = {
    items: getMenuItems()
  };

  return (
    <Card 
      className={`
        transform transition-all duration-300
        hover:shadow-[0_8px_30px_rgb(0,0,0,0.12)]
        hover:-translate-y-1
        border-0
        bg-gradient-to-b from-white to-gray-50/50
        backdrop-blur-xl
        rounded-xl
        overflow-hidden
        flex flex-col h-full
      `}
      bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <div className="relative flex-1">
        {/* Top bar color based on status */}
        <div 
          className={`
            absolute top-0 left-0 w-full h-2
            ${isActive 
              ? 'bg-gradient-to-r from-green-400 to-emerald-500'
              : needsSetup
                ? 'bg-gradient-to-r from-yellow-400 to-orange-500'
                : 'bg-gradient-to-r from-[#60a5fa]/60 to-[#93c5fd]/60'
            }
          `}
        />
        
        <div className="p-10 flex flex-col h-full">
          {/* Header section */}
          <div className="flex items-start justify-between mb-8">
            <div className="flex-1">
              <h3 className="text-2xl font-semibold text-gray-900 mb-4 tracking-tight">{title}</h3>
              <p className="text-gray-600 text-base leading-relaxed font-medium">{description}</p>
            </div>
            <div className="flex items-center ml-6">
              {isActive ? (
                <div className="flex items-center text-emerald-500 bg-emerald-50 px-3 py-1.5 rounded-full">
                  <CheckCircleFilled className="text-base" />
                  <span className="ml-2 text-sm font-semibold">Active</span>
                </div>
              ) : needsSetup ? (
                <div className="flex items-center text-orange-500 bg-orange-50 px-3 py-1.5 rounded-full">
                  <ClockCircleOutlined className="text-base" />
                  <span className="ml-2 text-sm font-semibold">Needs Setup</span>
                </div>
              ) : (
                <div className="flex items-center text-[#2563eb] bg-[#2563eb]/5 px-3 py-1.5 rounded-full">
                  <ClockCircleOutlined className="text-base" />
                  <span className="ml-2 text-sm font-semibold">Not Active</span>
                </div>
              )}
            </div>
          </div>

          {/* Action section pinned at the bottom */}
          <div className="flex items-center justify-between mt-auto">
            <div>
              {isActive ? (
                <span className="inline-flex items-center px-4 py-1.5 rounded-full text-sm font-semibold bg-emerald-50 text-emerald-700 ring-1 ring-emerald-100/50">
                  <CheckCircleFilled className="mr-1.5 text-xs" />
                  Channel Active
                </span>
              ) : needsSetup ? (
                <span className="inline-flex items-center px-4 py-1.5 rounded-full text-sm font-semibold bg-orange-50 text-orange-700 ring-1 ring-orange-100/50">
                  <ClockCircleOutlined className="mr-1.5 text-xs" />
                  Not Set Up
                </span>
              ) : (
                <span className="inline-flex items-center px-4 py-1.5 rounded-full text-sm font-semibold bg-[#2563eb]/5 text-[#2563eb] ring-1 ring-[#2563eb]/10">
                  Available to Add
                </span>
              )}
            </div>

            {/* Button text based on status */}
            {isActive ? (
              <Dropdown 
                trigger={['click']}
                menu={{ items: getMenuItems() }}
                placement="bottomRight"
              >
                <Button 
                  size="large"
                  className={`
                    group relative px-8 py-3.5 h-auto
                    text-base font-semibold
                    hover:from-green-500 hover:to-emerald-600 
                    hover:shadow-[0_8px_30px_rgb(16,185,129,0.3)] 
                    !border-emerald-500
                    !text-black rounded-lg
                    transition-all duration-300
                    flex items-center justify-center gap-3
                  `}
                >
                  <span className="relative z-10">Manage Channel</span>
                  <DownOutlined className="text-sm relative z-10" />
                  <div className="absolute inset-0 rounded-lg bg-white/10 blur-sm group-hover:blur-md transition-all duration-300" />
                </Button>
              </Dropdown>
            ) : (
              <Button 
                onClick={onStartSetup}
                size="large"
                className={`
                  group relative px-8 py-3.5 h-auto
                  text-base font-semibold
                  ${needsSetup
                    ? 'hover:from-yellow-500 hover:to-orange-600 hover:shadow-[0_8px_30px_rgb(249,115,22,0.3)] !border-orange-500'
                    : 'hover:from-[#60a5fa]/70 hover:via-[#93c5fd]/70 hover:to-[#93c5fd]/70 hover:shadow-[0_8px_30px_rgb(96,165,250,0.3)] !border-[#60a5fa]/70'
                  }
                  !text-black rounded-lg
                  transition-all duration-300
                  flex items-center justify-center gap-3
                `}
              >
                <span className="relative z-10">
                  {needsSetup ? 'Complete Setup' : 'Add Channel'}
                </span>
                <RightOutlined className="text-sm transition-transform duration-300 group-hover:translate-x-1 relative z-10" />
                <div className="absolute inset-0 rounded-lg bg-white/10 blur-sm group-hover:blur-md transition-all duration-300" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ServiceAutomationBox;